import { boot } from 'quasar/wrappers';
import * as Sentry from '@sentry/vue';
import { getStorage } from '@helpers/utils';

export default boot(({ Vue }) => {
  const { sentry_dsn } = getStorage('sz_whitelabel');

  const release = Vue.prototype?.$q?.config?.release; //busca a release do quasar.conf 

  Sentry.init({
    Vue: Vue,
    dsn: sentry_dsn || '',
    release: release,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      })
    ],
      logErrors: true,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      sampleRate: 1.0,
      tracePropagationTargets: [window.location.origin],
  });

  Sentry.configureScope(function(scope) {
    scope.setTag('_url', window.location.hostname);
  });

  //colocando o sentry como acesso global
  Vue.prototype.$sentry = Sentry

  Vue.config.errorHandler = function (err, vm, info) {
    // Captura e reporta a exceção para o Sentry
    console.log(Sentry.captureException(err));
    
    console.error('Erro capturado:', err);
  };
});
